import * as React from "react";
import { useUploadGuidebook } from "../hooks/useUploadGuidebook";
import { Button, makeStyles } from "@fluentui/react-components";
import { ArrowUpload24Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
  alert: {
    padding: "16px",
    backgroundColor: "#f8d7da",
    color: "#721c24",
    border: "1px solid #f5c6cb",
    borderRadius: "4px",
    marginBottom: "16px",
  },
  alertTitle: {
    fontWeight: "bold",
  },
  chip: {
    padding: "8px",
    border: "1px solid #0078d4",
    borderRadius: "16px",
    display: "inline-block",
    margin: "8px 0",
  },
});

export default function WZPTools() {
  const styles = useStyles();
  const {
    mutate: mutateUploadGuidebook,
    isError,
    isSuccess,
    error,
    data,
    file,
    handleFileChange,
  } = useUploadGuidebook();

  return (
    <div className={styles.root}>
      <div style={{ padding: "16px" }}>
        {isError && (
          <div className={styles.alert}>
            <div className={styles.alertTitle}>Błąd podczas wysyłania</div>
            {error instanceof Error ? `Błąd: ${error.message}` : "Nieznany błąd"}
          </div>
        )}
        {isSuccess && (
          <div
            className={styles.alert}
            style={{ backgroundColor: "#d4edda", color: "#155724", border: "1px solid #c3e6cb" }}
          >
            <div className={styles.alertTitle}>Żądanie wysłane</div>
            {data ? (
              <>
                Dodatkowe informacje: <strong>{data.extra_info}</strong>
                <br />
                ID przewodnika: <strong>{data.guidebook_id}</strong>
              </>
            ) : (
              "brak dodatkowych informacji z API"
            )}
          </div>
        )}

        <input onChange={handleFileChange} accept="*/*" type="file" />

        <input id="file-input" hidden onChange={handleFileChange} accept="*/*" type="file" />
        {file && (
          <>
            <div className={styles.chip}>{file.name}</div>
            <Button icon={<ArrowUpload24Regular />} onClick={() => mutateUploadGuidebook(file)}>
              {"Upload!"}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
