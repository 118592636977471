function lettersToNumber(letters: string): number {
  return letters.split("").reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0) - 1;
}

export enum MOVE_APFA_COLS {
  // BASICS
  "LEAD_END_2_H" = 7,
  "STRIPPING_LEAD_END_2_I" = 8,
  "PULL_OFF_LEAD_END_2_J" = 9,
  "LEAD_END_1_N" = 13,
  "STRIPPING_LEAD_END_1_O" = 14,
  "PULL_OFF_LEAD_END_1_P" = 15,
  "LEAD_END_3_AC" = 28,
  "STRIPPING_LEAD_END_3_AD" = 29,
  "PULL_OFF_LEAD_END_3_AE" = 30,
  "MM2_K" = lettersToNumber("K"),
  "Color_L" = lettersToNumber("L"),
  "Length_M" = lettersToNumber("M"),
  "Terminal_M" = lettersToNumber("N"),
  "Stripping_O" = lettersToNumber("O"),
  "Pull_Off_Length_P" = lettersToNumber("P"),
  "Font_Key" = lettersToNumber("Q"),
  "Lead_End_Text_R" = lettersToNumber("R"),
  "Distance_S" = lettersToNumber("S"),
  "Turn_T" = lettersToNumber("T"),
  "Text_U" = lettersToNumber("U"),
  "Distance_V" = lettersToNumber("V"),
  "Text_W" = lettersToNumber("W"),
  "Distance_X" = lettersToNumber("X"),
  "Turn_Y" = lettersToNumber("Y"),
  "MM2_Z" = lettersToNumber("Z"),
  "Color_AA" = lettersToNumber("AA"),
  "Length_AB" = lettersToNumber("AB"),
  "Terminal_AC" = lettersToNumber("AC"),
  "Stripping_AD" = lettersToNumber("AD"),
  "Pull_Off_Length_AE" = lettersToNumber("AE"),
  "Text_AF" = lettersToNumber("AF"),
  "Distance_AG" = lettersToNumber("AG"),
  "Turn_AH" = lettersToNumber("AH"),
  "Text_AI" = lettersToNumber("AI"),
  "Distance_AJ" = lettersToNumber("AJ"),
  "Text_AK" = lettersToNumber("AK"),
  "Distance_AL" = lettersToNumber("AL"),
  "Turn_AM" = lettersToNumber("AM"),
}

export type StringNumberOrUndefined = string | number | undefined;
export type StringNumberOrNull = string | number | null;
export const EMPTY_STRING = "";

export const PULL_OFF = 0.6;
export const REGEXP_PATTERN: RegExp = /[*#[]/;

export interface FromTo {
  from: string;
  to: string;
}

interface OptionalInfoInGuidebookAdd {
  extra_info?: string;
  guidebook_id?: number;
  success?: boolean;
}

export interface GuidebookAdd extends OptionalInfoInGuidebookAdd {
  id: number;
  success: boolean;
}

export interface GuidebookID {
  imported_id: number;
  status_code: number;
  status_description: string;
  guidebook_id: number;
}

export interface Wire {
  data_id: number;
  dicts_id: number;
  indeks: string;
  nazwa: string;
  dct_id: string;
  kolor: string | null;
  przekroj: string;
  wielozylowy: number;
  przekroj_wz: string;
  id: number;
  import_date: Date;
  label: string;
  type_of_dict: number;
  current_dict: number;
  imported_id: number;
}

export const wireKeys = [
  "data_id",
  "dicts_id",
  "indeks",
  "nazwa",
  "dct_id",
  "kolor",
  "przekroj",
  "wielozylowy",
  "przekroj_wz",
  "id",
  "import_date",
  "label",
  "type_of_dict",
  "current_dict",
  "imported_id",
];

export interface Snake {
  data_id: number;
  dicts_id: number;
  opis: string;
  material: string;
  srednica: string;
  nazwa: string;
  indeks: number;
  id: number;
  import_date: string;
  label: string;
  type_of_dict: number;
  current_dict: number;
  imported_id: number;
}

export const snakeKeys = [
  "data_id",
  "dicts_id",
  "opis",
  "material",
  "srednica",
  "nazwa",
  "indeks",
  "id",
  "import_date",
  "label",
  "type_of_dict",
  "current_dict",
  "imported_id",
];

export interface Isolation {
  data_id: number;
  dicts_id: number;
  indeks: number;
  nazwa: string;
  odizolowanie: number;
  aplikator: StringNumberOrNull;
  nr_producenta: StringNumberOrNull;
  nr_aplikatora: StringNumberOrNull;
  id: number;
  import_date: Date;
  label: string;
  type_of_dict: number;
  current_dict: number;
  imported_id: number;
}

export const isolationKeys = [
  "data_id",
  "dicts_id",
  "indeks",
  "nazwa",
  "odizolowanie",
  "aplikator",
  "nr_producenta",
  "nr_aplikatora",
  "id",
  "import_date",
  "label",
  "type_of_dict",
  "current_dict",
  "imported_id",
];

export interface NotFoundItem {
  detail: "Dict item not found";
}
