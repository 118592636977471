import * as React from "react";
import {
  Label,
  makeStyles,
  SelectTabData,
  SelectTabEvent,
  Slider,
  Tab,
  TabList,
  TabValue,
  Text,
  tokens,
  useId,
} from "@fluentui/react-components";
import { AppProps } from "./index";
import AlfaTools from "./views/AlfaTools";
import WZPTools from "./views/WZPTools";
import Header from "./components/Header";
import IsolationTools from "./views/IsolationTools";
import { useState } from "react";
import WireTools from "./views/WireTools";
import SnakeTools from "./views/SnakeTools";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    display: "flex",
    gap: "3rem",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    padding: "2rem",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "5rem",
    backgroundColor: tokens.colorNeutralBackground3,
  },
  items: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
});

enum TabValues {
  AlfaTools = "1",
  WZPTools = "2",
  DictionaryTools = "3",
}

export default function App({ title }: AppProps) {
  const styles = useStyles();
  const [selectedValue, setSelectedValue] = React.useState<TabValue>(TabValues.AlfaTools);
  const idX = useId("x-offset");
  const idY = useId("y-offset");
  const [xOffset, setXOffset] = useState(1);
  const [yOffset, setYOffset] = useState(0);

  const onTabSelect = (_event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Header title={title} logo={"assets/logo_black.png"} message="Makra LSN" />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
            <Tab id="Alfa tools" value={TabValues.AlfaTools}>
              Alfa tools
            </Tab>
            <Tab id="WZP tools" value={TabValues.WZPTools}>
              WZP tools
            </Tab>
            <Tab id="Dictionary tools" value={TabValues.DictionaryTools}>
              Dictionary tools
            </Tab>
          </TabList>

          <div className={styles.content}>
            {selectedValue === TabValues.AlfaTools && <AlfaTools />}
            {selectedValue === TabValues.WZPTools && <WZPTools />}
            {selectedValue === TabValues.DictionaryTools && (
              <div className={styles.items}>
                <div>
                  <Label htmlFor={idX}>Offset w osi x - {xOffset}</Label>
                  <Slider min={0} max={20} defaultValue={xOffset} onChange={(_e, v) => setXOffset(v.value)} id={idX} />
                </div>
                <div>
                  <Label htmlFor={idY}>Offset w osi y - {yOffset}</Label>
                  <Slider min={0} max={20} defaultValue={xOffset} onChange={(_e, v) => setYOffset(v.value)} id={idY} />
                </div>
                <IsolationTools x={xOffset} y={yOffset} />
                <WireTools x={xOffset} y={yOffset} />
                <SnakeTools x={xOffset} y={yOffset} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Text block align="start" size={300}>
          © {new Date().getFullYear()} LSN. Wersja z dnia 12 sierpnia 2024 r.
        </Text>
      </div>
    </div>
  );
}
