import * as React from "react";
import { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Link,
  makeStyles,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarTitle,
} from "@fluentui/react-components";
import { ArrowAutofitUpRegular, ArrowSwap24Regular, ArrowUpload24Regular } from "@fluentui/react-icons";
import { useExportAlfaWZP } from "../hooks/useExportAlfaWZP";
import { getGuidebookID } from "../services/services";
import { exportToCSV, moveAlfa } from "../utils/macros";

/* global setInterval, clearInterval */

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",

    gap: "1rem",
    width: "100%",
    height: "100%",
  },
  messageBar: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  actionButtons: {
    display: "flex",
    justifyItems: "start",
    flexDirection: "column",
    gap: "1rem",
  },
});

export default function AlfaTools() {
  const styles = useStyles();
  const queryClient = useQueryClient();
  const {
    data: guidebookUploadData,
    isSuccess: isUploadedToWZP,
    isPending: isLoadingUploadToWZP,
    exportFileToWZP,
    isError: isErrorOnExport,
    error,
  } = useExportAlfaWZP();

  const {
    data: guidebookData,
    isSuccess: isRecivedDataAboutGuidebook,
    isError: isErrorAboutGuidebookId,
  } = useQuery({
    queryKey: ["guidebookData", guidebookUploadData?.id],
    queryFn: () => getGuidebookID(guidebookUploadData?.id),
    enabled: isUploadedToWZP && !isLoadingUploadToWZP && !!guidebookUploadData?.id,
  });

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (isUploadedToWZP && guidebookData?.status_description !== "Poprawny") {
        await queryClient.invalidateQueries({ queryKey: ["guidebookData"] });
      }
    }, 2000);

    if (guidebookData?.status_description === "Błedy walidacji") {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [guidebookData?.status_description]);

  return (
    <div className={styles.container}>
      <div className={styles.actionButtons}>
        <Button icon={<ArrowSwap24Regular />} onClick={moveAlfa}>
          Move
        </Button>
        <Button icon={<ArrowUpload24Regular />} onClick={exportFileToWZP}>
          Export Alfa - wersja WZP
        </Button>
        <Button icon={<ArrowAutofitUpRegular />} onClick={exportToCSV}>
          Export Alfa - wersja standalone
        </Button>
      </div>
      <div className={styles.messageBar}>
        {isErrorOnExport && (
          <MessageBar intent="error">
            <MessageBarBody>
              <MessageBarTitle>Błąd podczas wysyłania żądania do API</MessageBarTitle>
              {error instanceof Error ? `Błąd: ${error.message}` : "Nieznany błąd"}
            </MessageBarBody>
          </MessageBar>
        )}
        {isErrorAboutGuidebookId && (
          <MessageBar intent="error">
            <MessageBarBody>
              <MessageBarTitle>Błąd podczas pobierania danych o przewodniku</MessageBarTitle>
              {error instanceof Error ? `Błąd: ${error.message}` : "Nieznany błąd"}
            </MessageBarBody>
          </MessageBar>
        )}
        {isRecivedDataAboutGuidebook && guidebookData.status_description !== "Poprawny" && (
          <MessageBar intent="warning">
            <MessageBarBody>
              <MessageBarTitle>Przewodnik nie jest gotowy do pobrania</MessageBarTitle>
              {guidebookData.status_description}
            </MessageBarBody>
            <MessageBarActions></MessageBarActions>
          </MessageBar>
        )}

        {guidebookData?.status_description === "Poprawny" && (
          <MessageBar intent="success">
            <MessageBarBody>
              <MessageBarTitle>Przewodnik nie jest gotowy do pobrania</MessageBarTitle>
              {guidebookData.status_description}
            </MessageBarBody>
            <MessageBarActions>
              <Link href={`https://dev.lsn.pl:8080/guidebooks/get_makro_1_csv?id=${guidebookData?.guidebook_id}`}>
                Pobierz CSV
              </Link>
            </MessageBarActions>
          </MessageBar>
        )}
      </div>
    </div>
  );
}
