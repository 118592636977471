export const exportAlfaHeader = `
"TopConvert Example File ""Example_2""";;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
Job_S2;"Job referencing the sequence ""SeqM"" with 4 leads. ";;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;Each lead has a marking text;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
Standard Application Crimp to Crimp (Lead 1);;;;;;;;;;;;;;;;;;;;;;;;;;;Double Crimp (Lead 2);;;;;;;;;;;;Weiteres;;;;;;;;;
Order (Job) and Article;;;;;;;Lead-End (End 2);;;Wire/Cable;;;Lead-Begin (End 1);;;;Marking Text Lead 1;;;;;;;;Wire/Cable;;;Lead-Begin (End 3);;;Marking Text Lead 2;;;;;;;;Seal Key;;;Sleeve Key;;;Tinning Length    ;;;
Order (Job);Job Pos.;PCS;Batch;Job Hint;Article;Job Name;Terminal;Stripping length;Pull-off length;mm2;Color;Length;Terminal;Stripping length;Pull-off length;Font Key;Lead-End;;;Continusely;;Lead-Begin;;;mm2;Color;Length;Terminal;Stripping length;Pull-off length;Lead-End;;;Continusely;;Lead-Begin;;;End 2;End 1;End3;End 2;End 1;End 3;End 2;End 1;End 3;
;;;;;;;;;;;;;;;;;Text;Distance;Turn;Text;Distance;Text;Distance;Turn;;;;;;;Text;Distance;Turn;Text;Distance;Text;Distance;Turn;;;;;;;;;;Grupa
`.trim();
