import { GuidebookAdd, GuidebookID, Isolation, NotFoundItem, Snake, Wire } from "../types/types";
import axios, { AxiosResponse } from "axios";

/* global Blob,File, FormData */

export const sendFileToServer = async (byteArray: Iterable<number>, fileName: string): Promise<GuidebookAdd> => {
  const data = new Blob([new Uint8Array(byteArray)]);
  const form = new FormData();

  form.append("file", data, `${fileName}.xlsm`);

  const response = await axios.post<GuidebookAdd>("https://dev.lsn.pl:8080/guidebooks/add", form, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });

  return response.data;
};

export const sendCSVFileToServer = async (blob: Blob, filename: string): Promise<string> => {
  const formData = new FormData();
  formData.append("file", blob, filename + ".csv");

  const response = await axios.post<{
    publicUrl: string;
  }>("https://dev.lsn.pl:5050/upload", formData);

  return response.data.publicUrl.replace("http://dev.lsn.pl", "https://dev.lsn.pl:5050");
};

export const sendPureFileToServer = async (file: File): Promise<GuidebookAdd> => {
  const form = new FormData();

  form.append("file", file);

  const response = await axios.post<GuidebookAdd>("https://dev.lsn.pl:8080/guidebooks/add", form, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });

  return response.data;
};

export const getGuidebookID = async (id: number) => {
  const response = await axios.get<GuidebookID>(`https://dev.lsn.pl:8080/guidebooks/get_by_imported_id?id=${id}`);

  return response.data;
};

export const getWiresById = async (index: number) => {
  try {
    return await axios
      .get<Wire, AxiosResponse<Wire, NotFoundItem>>(`https://dev.lsn.pl:8080/dicts/wires/get?index=${index}`)
      .then((res) => res.data);
  } catch (e) {
    return { message: "Nie znaleziono", error: e };
  }
};

export const getSnakeById = async (index: number) => {
  try {
    return await axios
      .get<Snake, AxiosResponse<Snake, NotFoundItem>>(`https://dev.lsn.pl:8080/dicts/snakes/get?index=${index}`)
      .then((res) => res.data);
  } catch (e) {
    return { message: "Nie znaleziono", error: e };
  }
};

export const getIsolationById = async (index: number) => {
  try {
    return await axios
      .get<
        Isolation,
        AxiosResponse<Isolation, NotFoundItem>
      >(`https://dev.lsn.pl:8080/dicts/isolations/get?index=${index}`)
      .then((res) => res.data);
  } catch (e) {
    return { message: "Nie znaleziono", error: e };
  }
};
