import { useMutation } from "@tanstack/react-query";
import { sendPureFileToServer } from "../services/services";
import { ChangeEvent, useState } from "react";

/* global File, HTMLInputElement */

export const useUploadGuidebook = () => {
  const [file, setFile] = useState<File | null>(null);
  const { mutate, data, isPending, isSuccess, ...other } = useMutation({
    mutationFn: (file: File) => sendPureFileToServer(file),
    onSuccess: () => {
      setFile(null);
    },
  });

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  return {
    handleFileChange,
    file,
    mutate,
    data,
    isPending,
    isSuccess,
    ...other,
  };
};
