import * as React from "react";
import { useState } from "react";
import { Button, Dropdown, makeStyles, Option, useId } from "@fluentui/react-components";
import { getSnakeData } from "../utils/macros";
import { isolationKeys, Snake } from "../types/types";
import { Search20Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
});

export default function SnakeTools({ x, y }: { x: number; y: number }) {
  const [snakeSearchType, setSnakeSearchType] = useState<keyof Snake>("indeks");
  const dropdownId = useId("dropdown-default");

  const styles = useStyles();
  return (
    <div className={styles.root}>
      <label id={dropdownId}>Wybierz typ wyszukiwania dla odizolowania</label>
      <Dropdown
        value={snakeSearchType}
        onOptionSelect={(_event, value) => setSnakeSearchType(value.optionValue as keyof Snake)}
        aria-labelledby={dropdownId}
        placeholder="Wybierz typ"
      >
        {isolationKeys.map((option) => (
          <Option key={option}>{option}</Option>
        ))}
      </Dropdown>

      <Button appearance="outline" icon={<Search20Regular />} onClick={() => getSnakeData(snakeSearchType, x, y)}>
        Wyszukaj w końcówkach - {snakeSearchType}
      </Button>
    </div>
  );
}
