import {
  EMPTY_STRING,
  Isolation,
  MOVE_APFA_COLS,
  PULL_OFF,
  Snake,
  StringNumberOrUndefined,
  Wire,
} from "../types/types";
import {
  moveWithEmptyHAndACWithIndex,
  removeSpecialCharacters,
  replacePolishCharacters,
  swap,
  testRegexpAndReturnValue,
  wrapLongText,
} from "./helpers";
import { getIsolationById, getSnakeById, getWiresById, sendCSVFileToServer } from "../services/services";
import { exportAlfaHeader } from "../statuc/export-alfa-header";

/* global Excel console, Blob, Office  */

export const getIsolationData = async (searchValue: keyof Isolation, x: number, y: number) => {
  await Excel.run(async (context) => {
    let activeSheet = context.workbook.worksheets.getActiveWorksheet();
    let range = context.workbook.getSelectedRange();
    range.load("address");
    await context.sync();
    let dataRange = activeSheet.getRange(range.address).load(["rowCount", "values", "address"]);
    await context.sync();

    const result = await Promise.all(
      dataRange.values.map(
        async (row) =>
          await Promise.all(
            row.map((cell) =>
              getIsolationById(removeSpecialCharacters(cell)).then((result) =>
                "message" in result ? result.message : result[searchValue]
              )
            )
          )
      )
    );
    const newValuesRange = dataRange.getOffsetRange(y, x).load(["rowCount", "values", "address"]);
    newValuesRange.values = result;
  });
};

export const getWireData = async (searchValue: keyof Wire, x: number, y: number) => {
  await Excel.run(async (context) => {
    let activeSheet = context.workbook.worksheets.getActiveWorksheet();
    let range = context.workbook.getSelectedRange();
    range.load("address");
    await context.sync();
    let dataRange = activeSheet.getRange(range.address).load(["rowCount", "values", "address"]);
    await context.sync();

    const result = await Promise.all(
      dataRange.values.map(
        async (row) =>
          await Promise.all(
            row.map((cell) =>
              getWiresById(removeSpecialCharacters(cell)).then((result) =>
                "message" in result ? result.message : result[searchValue]
              )
            )
          )
      )
    );
    const newValuesRange = dataRange.getOffsetRange(y, x).load(["rowCount", "values", "address"]);
    newValuesRange.values = result;
  });
};

export const getSnakeData = async (searchValue: keyof Snake, x: number, y: number) => {
  await Excel.run(async (context) => {
    let activeSheet = context.workbook.worksheets.getActiveWorksheet();
    let range = context.workbook.getSelectedRange();
    range.load("address");
    await context.sync();
    let dataRange = activeSheet.getRange(range.address).load(["rowCount", "values", "address"]);
    await context.sync();

    const result = await Promise.all(
      dataRange.values.map(
        async (row) =>
          await Promise.all(
            row.map((cell) =>
              getSnakeById(removeSpecialCharacters(cell)).then((result) =>
                "message" in result ? result.message : result[searchValue]
              )
            )
          )
      )
    );
    const newValuesRange = dataRange.getOffsetRange(y, x).load(["rowCount", "values", "address"]);
    newValuesRange.values = result;
  });
};
export const moveAlfa = async () => {
  try {
    await Excel.run(async (context) => {
      let activeSheet = context.workbook.worksheets.getActiveWorksheet();

      let dataRange = activeSheet.getUsedRange(true).getOffsetRange(8, 0).load(["rowCount", "values", "address"]);
      await context.sync();

      dataRange.values = dataRange.values.map((row: StringNumberOrUndefined[]) => {
        const valuesWithoutUnnecessaryData = row.map((col: string | number | undefined, index) => {
          if (index === MOVE_APFA_COLS.LEAD_END_1_N) {
            return testRegexpAndReturnValue(col);
          } else if (index === MOVE_APFA_COLS.LEAD_END_2_H) {
            return testRegexpAndReturnValue(col);
          } else if (index === MOVE_APFA_COLS.LEAD_END_3_AC) {
            return testRegexpAndReturnValue(col);
          }
          return col;
        });

        const valuesAfterFistMove = valuesWithoutUnnecessaryData.map((col, index) => {
          const currentValues = {
            leadEnd1: valuesWithoutUnnecessaryData[MOVE_APFA_COLS.LEAD_END_1_N],
            leadEnd2: valuesWithoutUnnecessaryData[MOVE_APFA_COLS.LEAD_END_2_H],
            leadEnd3: valuesWithoutUnnecessaryData[MOVE_APFA_COLS.LEAD_END_3_AC],
            strippingEnd1: replacePolishCharacters(valuesWithoutUnnecessaryData[MOVE_APFA_COLS.STRIPPING_LEAD_END_1_O]),
            strippingEnd2: replacePolishCharacters(valuesWithoutUnnecessaryData[MOVE_APFA_COLS.STRIPPING_LEAD_END_2_I]),
            strippingEnd3: replacePolishCharacters(
              valuesWithoutUnnecessaryData[MOVE_APFA_COLS.STRIPPING_LEAD_END_3_AD]
            ),
          };
          const isPossibleToMoveValues: boolean =
            valuesWithoutUnnecessaryData[MOVE_APFA_COLS.LEAD_END_1_N] === EMPTY_STRING &&
            valuesWithoutUnnecessaryData[MOVE_APFA_COLS.LEAD_END_2_H] !== EMPTY_STRING;

          // Move End2 to End1
          if (isPossibleToMoveValues && index === MOVE_APFA_COLS.LEAD_END_1_N) {
            return currentValues.leadEnd2;
          }
          // Clear End2 cell
          else if (isPossibleToMoveValues && index === MOVE_APFA_COLS.LEAD_END_2_H) return EMPTY_STRING;
          // Move stripping End2 to End1
          else if (isPossibleToMoveValues && index === MOVE_APFA_COLS.STRIPPING_LEAD_END_1_O) {
            return currentValues.strippingEnd2;
          }
          // Move stripping End1 to End2
          else if (isPossibleToMoveValues && index === MOVE_APFA_COLS.STRIPPING_LEAD_END_2_I) {
            return currentValues.strippingEnd1;
          }
          return col;
        });

        const calculatedValues = valuesAfterFistMove.map((col, index) => {
          const currentValues = {
            strippingEnd1: valuesAfterFistMove[MOVE_APFA_COLS.STRIPPING_LEAD_END_1_O],
            strippingEnd2: valuesAfterFistMove[MOVE_APFA_COLS.STRIPPING_LEAD_END_2_I],
            strippingEnd3: valuesAfterFistMove[MOVE_APFA_COLS.STRIPPING_LEAD_END_3_AD],
          };
          if (index === MOVE_APFA_COLS.PULL_OFF_LEAD_END_1_P && currentValues.strippingEnd1 !== EMPTY_STRING) {
            return +currentValues.strippingEnd1 * PULL_OFF;
          } else if (index === MOVE_APFA_COLS.PULL_OFF_LEAD_END_2_J && currentValues.strippingEnd2 !== EMPTY_STRING) {
            return +currentValues.strippingEnd2 * PULL_OFF;
          } else if (index === MOVE_APFA_COLS.PULL_OFF_LEAD_END_3_AE && currentValues.strippingEnd3 !== EMPTY_STRING) {
            return +currentValues.strippingEnd3 * PULL_OFF;
          }
          return col;
        });

        const isPossibleToMoveValuesWithIndex: boolean =
          calculatedValues[MOVE_APFA_COLS.LEAD_END_2_H] === EMPTY_STRING &&
          calculatedValues[MOVE_APFA_COLS.LEAD_END_3_AC] !== EMPTY_STRING;

        if (isPossibleToMoveValuesWithIndex) {
          const valuesToMove = calculatedValues.slice();
          moveWithEmptyHAndACWithIndex.map(({ from, to }) => swap(valuesToMove, from, to));
          return valuesToMove;
        }
        return calculatedValues;
      });
    });
  } catch (error) {
    console.error(error);
  }
};

export const exportToCSV = async () => {
  try {
    await Excel.run(async (context) => {
      const currentSheet = context.workbook.worksheets.getActiveWorksheet();
      const worksheet = currentSheet.load("name");
      const range = currentSheet.getUsedRange(true);

      range.load("values");
      await context.sync();

      const headerValues = range.values.filter((_, index) => index < 12);
      const wireGuardValues = range.values.filter((_, index) => index >= 12 && index % 2 !== 0);

      const makeEmptyItems = (count: number) => Array.from({ length: count }, () => EMPTY_STRING);

      const output = wireGuardValues
        .map((row, index, array) => {
          const lastOrNextIndex = index === array.length - 1 ? index : index + 1;

          const leftWire = {
            wire: row[3],
            wireStripping: row[4],
          };

          const nextRowLeftWire = {
            wire: array[lastOrNextIndex][3],
            wireStripping: array[lastOrNextIndex][4],
          };

          const rightWire = {
            wire: row[15],
            wireStripping: row[14],
          };

          const nextRowRightWire = {
            wire: array[lastOrNextIndex][15],
            wireStripping: array[lastOrNextIndex][14],
          };

          const textDistanceTurn = [row[8], "30", "1", row[8], "60", row[8], "30", "0"];
          const wireParams = [row[10], row[9], row[11]];

          let end2 = [wrapLongText(leftWire.wire), leftWire.wireStripping, EMPTY_STRING];
          let end1 = [wrapLongText(rightWire.wire), rightWire.wireStripping, EMPTY_STRING];
          let end3 = makeEmptyItems(22);

          const isLeftWireIncludesMultiWire =
            typeof leftWire.wire === "string" && leftWire.wire.includes("^") && leftWire.wire.length >= 12;

          const isRightWireIncludesMultiWire =
            typeof rightWire.wire === "string" && rightWire.wire.includes("^") && rightWire.wire.length >= 12;

          if (isLeftWireIncludesMultiWire) {
            end3 = [
              ...wireParams,
              nextRowRightWire.wire,
              nextRowRightWire.wireStripping,
              EMPTY_STRING,
              ...textDistanceTurn,
              ...makeEmptyItems(9),
            ];
          } else if (isRightWireIncludesMultiWire) {
            end3 = [
              ...wireParams,
              wrapLongText(nextRowLeftWire.wire),
              nextRowLeftWire.wireStripping,
              EMPTY_STRING,
              ...textDistanceTurn,
              ...makeEmptyItems(9),
            ];
          }

          if (row.some((item) => typeof item === "string" && item === "^")) {
            return undefined;
          }

          return [
            headerValues[4][14], // Order (Job)
            row[9], // Job Pos.
            1, // PCS
            headerValues[5][14], // Batch
            EMPTY_STRING, // Job Hint
            `${headerValues[8][8]}_${row[0]}`, // Article
            row[8], // Job Name
            ...end2,
            ...wireParams,
            ...end1,
            row[10], // Font Key
            ...textDistanceTurn,
            ...end3,
            `${headerValues[2][3]}, ${headerValues[4][3]}, WIAZKI`, // Group
          ];
        })
        .filter(Boolean);

      const csvContent = exportAlfaHeader + "\n" + output.map((row) => row.join(";")).join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const publicUrl = await sendCSVFileToServer(
        blob,
        worksheet.name +
          "-" +
          new Date()
            .toLocaleString("pl-PL", { timeZone: "Europe/Warsaw" })
            .replace(/ /g, "-")
            .replace(/:/g, "-")
            .replace(/\./g, "-")
            .replace(/,/g, "-")
      );
      Office.context.ui.openBrowserWindow(publicUrl);
    });
  } catch (error) {
    console.error("Error exporting to CSV: ", {
      error,
    });
  }
};
