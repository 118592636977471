import { useMutation, useQueryClient } from "@tanstack/react-query";
import { sendFileToServer } from "../services/services";
import { getFileContents } from "../utils/helpers";

/* global Office */
export const useExportAlfaWZP = () => {
  const queryClient = useQueryClient();

  const { mutate, data, isPending, isSuccess, ...other } = useMutation({
    mutationFn: (file: Iterable<number>) => sendFileToServer(file, `przewodnik-${new Date()}-exported-from-addin`),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["guidebookData", "csv"] }),
  });

  const exportFileToWZP = () => {
    Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 6400 }, async (result) => {
      const file = await getFileContents(result.value);
      mutate(file);
    });
  };

  return { data, isSuccess, exportFileToWZP, isPending, ...other };
};
