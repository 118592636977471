import { FromTo, REGEXP_PATTERN, StringNumberOrUndefined } from "../types/types";

/* global Office */

export function lettersToNumber(letters: string): number {
  return letters.split("").reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0) - 1;
}

export function removeSpecialCharacters(value: any) {
  return value.toString().replaceAll("[^\\w\\s]", "");
}

export function testRegexpAndReturnValue(col: StringNumberOrUndefined): StringNumberOrUndefined {
  const column = String(col);
  if (column.includes("^")) {
    return column.replace("^", "");
  } else if (REGEXP_PATTERN.test(column)) {
    return column.replace(REGEXP_PATTERN, "");
  }
  return col;
}

export function replacePolishCharacters(input: string | number | undefined): string {
  if (input === undefined) {
    return "";
  }

  const text = input.toString();
  const polishChars: { [key: string]: string } = {
    a: "a",
    ć: "e",
    ł: "l",
    ń: "n",
    ó: "o",
    ś: "s",
    ź: "z",
    ż: "z",
    Ą: "A",
    Ć: "C",
    Ę: "E",
    Ł: "L",
    Ń: "N",
    Ó: "O",
    Ś: "S",
    Ź: "Z",
    Ż: "Z",
  };

  let result = "";
  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    result += polishChars[char] || char;
  }

  return result;
}

const H_IS_EMPTY_AND_AC_HAS_INDEX: FromTo[] = [
  {
    from: "K",
    to: "Z",
  },
  {
    from: "L",
    to: "AA",
  },
  {
    from: "M",
    to: "AB",
  },
  {
    from: "O",
    to: "AD",
  },
  {
    from: "R",
    to: "AF",
  },
  {
    from: "S",
    to: "AG",
  },
  {
    from: "T",
    to: "AH",
  },
  {
    from: "U",
    to: "AI",
  },
  {
    from: "V",
    to: "AJ",
  },
  {
    from: "W",
    to: "AK",
  },
  {
    from: "X",
    to: "AL",
  },
  {
    from: "Y",
    to: "AM",
  },
  {
    from: "AP",
    to: "AN",
  },
];

export const moveWithEmptyHAndACWithIndex = H_IS_EMPTY_AND_AC_HAS_INDEX.map(({ from, to }) => ({
  to: lettersToNumber(to),
  from: lettersToNumber(from),
}));

export const swap = (array: any[], i: number, j: number) => ([array[i], array[j]] = [array[j], array[i]]);
export function divideArr(col: StringNumberOrUndefined[], start, end, newItem): StringNumberOrUndefined[] {
  return col.splice(start, end, ...newItem);
}

export const wrapLongText = (text: string): string => {
  if (isNaN(+text)) {
    return text;
  }
  return `\`${text}`;
};
export const getFileContents = (file: Office.File): Promise<Iterable<number>> => {
  return new Promise((resolve, reject) => {
    const expectedSliceCount = file.sliceCount;
    const fileSlices: Uint8Array[] = [];

    const getFileContentsHelper = () => {
      file.getSliceAsync(fileSlices.length, (result) => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          file.closeAsync();
          return reject(result.error);
        }

        fileSlices.push(result.value.data);

        if (fileSlices.length === expectedSliceCount) {
          file.closeAsync();
          const array = fileSlices.reduce((acc, slice) => acc.concat(Array.from(slice)), []);
          resolve(array);
        } else {
          getFileContentsHelper();
        }
      });
    };
    getFileContentsHelper();
  });
};
